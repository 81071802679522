import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Anchor, Button, Fieldset, Panel, Window, WindowHeader } from "react95";
import styled from "styled-components";

import Layout from "../components/layout";
import ReadingTime from "../components/estimated-read-time";
import { centeredWindowListItem, iconStyle } from "../styles";

const TruncatedSpan = styled.span`
  line-height: 1.5rem;
  width: 100%;
`;

const BlogIndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge =>
      // if show drafts, show all, else check for date and no draft flag:
      process.env.GATSBY_SHOW_DRAFTS
        ? true
        : !!edge.node.frontmatter.date && !edge.node.frontmatter.draft
    )
    .map(edge => {
      const { node } = edge || {};
      if (!node) {
        return null;
      }

      const { id, excerpt, frontmatter, timeToRead, wordCount } = node;

      if (!frontmatter) {
        return null;
      }

      const { words } = wordCount;

      const { date, slug, summary, title } = frontmatter;
      return (
        <Window as="li" key={id} style={centeredWindowListItem}>
          <WindowHeader className="window-header-icon" style={{ height: 'fit-content' }}>
            <Link className="blog-link link" to={slug}>
              <StaticImage
                alt=""
                aria-hidden="true"
                src="../images/help.png"
                style={iconStyle}
              />
              <TruncatedSpan style={{ textOverflow: 'ellipsis' }}>
                {title}
              </TruncatedSpan>
            </Link>
          </WindowHeader>
          <p style={{ margin: '1rem 0.5rem' }}>{summary}</p>
          <Fieldset label="Excerpt" style={{ margin: '2rem 1rem 1rem 1rem', padding: '1rem 2rem' }}>
            <p>{excerpt}</p>
          </Fieldset>
          <Link className="blog-link link" to={slug} style={{ float: 'right', marginRight: '4ch' }}>
            <Anchor as="span">Read more...</Anchor>
          </Link>
          <Panel
            variant='well'
            className="blog-metadata"
            style={{ marginTop: '1rem', padding: '0.1rem 0.25rem', width: '100%' }}
          >
            <p>Posted {date || 'never'}</p>
            <ReadingTime wordcount={words} minutes={timeToRead} />
          </Panel>
        </Window>
      )
    });

  return (
    <Layout
      description="A collection of blog posts on game development and game design."
      subtitle={'Blog Index'}
    >
      <ul className="blog-posts-list">
        {Posts}
      </ul>
      <Button style={{ display: 'block', margin: '0 auto' }}>
        <Anchor className="link" type="application/rss-xml" rel="alternate" href="/rss.xml">Subscribe with RSS</Anchor>
      </Button>
    </Layout>
  );
}


export default BlogIndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 240)
          id
          timeToRead
          wordCount {
            words
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            draft
            slug
            summary
            title
          }
        }
      }
    }
  }
`;
